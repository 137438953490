import React from 'react';
import BuyNowButton from '../../common/buy-now-button'
import './index.css';
import cx from 'classnames';

export default ({locale}) => {
    const classes = cx({
        'middle-buy-now__button': true,
        'middle-buy-now__button--ru': locale === 'ru'
    });
    return (
        <div className="middle-buy-now">
            <span className={classes}>
                <BuyNowButton link={"https://www.ipharma.ee/vitamin-d-pro-expert-400-iu-tilgad-10-ml"} large/>
            </span>
            <picture>
                <source 
                    media="(max-width: 375px)"
                    srcSet="/static/images/middle-bottle-375.png"
                />
                <source 
                    media="(max-width: 1000px)"
                    srcSet="/static/images/middle-bottle-mobile.png"
                />
                <img 
                    className="middle-buy-now__bottle"
                    alt=""
                    src="/static/images/middle-bottle.png"
                />
            </picture>
        </div>
    )
};
