import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div id="youtube" className="youtube-specialists">
        <HeaderText/>
        <div className="youtube-items">
            <Item
                link={<iframe title="Milline on D-vitamiini roll lapse vaimses ja füüsilises arengus?" width="380"
                              height="250"
                              src="https://www.youtube.com/embed/WKxNVY50AcA">
                </iframe>}
            >
            </Item>
            <Item
                link={<iframe title="Milline on D-vitamiini roll lapse immuunsuse kujunemisel?" width="380"
                              height="250"
                              src="https://www.youtube.com/embed/3dNVhd5X0FA">
                </iframe>}
            >
            </Item>
            <Item
                link={<iframe title="Kuidas saada aru, et lapsel on D-vitamiini puudus?" width="380" height="250"
                              src="https://www.youtube.com/embed/6bnmn0UYbTQ">
                </iframe>}
                className="item3-margin"
            >
            </Item>
        </div>
    </div>
));