import React from 'react';
import Item from './Item';
import './index.css';
import BuyNowButton from '../../common/buy-now-button';
import {injectIntl, FormattedMessage} from "react-intl";

export default injectIntl(({intl}) => (
    <div className="vitamin-pregnancy">
        <div
            className="vitamin-pregnancy__header"
            dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.pregnancy.header'})}}
        />
        <div className="vitamin-pregnancy__items">
            <Item
                url="/static/images/vitamin-pregnancy-woman.png"
                header={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.pregnancy.1.header'})}}/>}
                text={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.pregnancy.1.text'})}}/>}
            />
            <Item
                reverse
                url="/static/images/vitamin-pregnancy-baby.png"
                header={<FormattedMessage id="vitamin.pregnancy.2.header" />}
                text={<span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: 'vitamin.pregnancy.2.text'})}}/>}
            />
            <div className="vitamin-pregnancy-bottle">
                <span className="vitamin-pregnancy__button">
                    <BuyNowButton link={"https://www.ipharma.ee/vitamin-d3-pro-expert-4000-iu-olikapslid-n90"} large />
                </span>
                <picture>
                    <source 
                        media="(max-width: 375px)"
                        srcSet="/static/images/vitamin-pregnancy-bottle--mobile.png"
                    />
                    <img 
                        className="vitamin-pregnancy-bottle__img"
                        alt=""
                        src="/static/images/vitamin-pregnancy-bottle.png"
                    />
                </picture>
            </div>
        </div>
    </div>
));
