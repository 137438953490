import React from 'react';
import HeaderText from "./HeaderText";
import Item from "./Item";
import './index.css';
import {injectIntl} from "react-intl";

export default injectIntl(({intl}) => (
    <div id="youtube" className="youtube">
        <HeaderText/>
        <div className="youtube-items">
            <Item
                link={<iframe title="Kuidas säilitada lapse suviselt hea tervis ja mängulust ka sügisperioodil?"
                              width="380" height="250"
                              src="https://www.youtube.com/embed/L4MepzQepJw">
                </iframe>}
            >
            </Item>
            <Item
                link={<iframe title="Pisiasi, mis muudab lapse kooliaja edukamaks" width="380" height="250"
                              src="https://www.youtube.com/embed/NBRcWZJtX7E">
                </iframe>}
            >
            </Item>
        </div>
    </div>
));