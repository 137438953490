import React from 'react';
import './Locations.css';

export default () => (
    <div className="footer-locations">
        <a target="_blank" href="https://www.ipharma.ee/vitamin-d3-pro-expert-800iu-noobikommid-n60">
            <img
                src="/static/svg/ipharma.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.benu.ee/tooted/vitamiinid-ja-mineraalained/monovitamiinid/vitamiin-d?vars/brand/pro-expert">
            <img
                src="/static/svg/benu.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.apotheka.ee/tooted/vitamiinid/vitamiinid-ja-mineraalained/monovitamiinid/d-ja-e-vitamiin?brand=PRO+EXPERT">
            <img
                src="/static/svg/apotheka.svg"
            />
        </a>
        <a target="_blank" href="https://www.azeta.ee/vitamiin-d?brand%5B0%5D=PRO%20EXPERT&page=1&per_page=48">
            <img
                src="/static/svg/euro-apteek.svg"
            />
        </a>
        <a target="_blank"
           href="https://www.sudameapteek.ee/tooted/vitamiinid-ja-toidulisandid/vitamiinid-ja-mineraalained/monovitamiinid/d-vitamiin?brand=PRO+EXPERT">
            <img
                src="/static/svg/sydame-apteek.svg"
            />
        </a>
    </div>
);
